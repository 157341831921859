

import {Options, Vue} from "vue-class-component"
import Telephone from "@/model/common/carddav/Telephone"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import InputText from "primevue/inputtext"
import MultiSelect from "primevue/multiselect"
import Button from "primevue/button"
import Dropdown from "primevue/dropdown"

@Options({
  components: {InputText, MultiSelect, Button, Dropdown},
  //@ts-ignore
  props: {
    phone: [Telephone, Object],
    readOnly: {
      type: Boolean,
      default: false
    },
    isv4: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'delete' ]
})
export default class PhoneField extends Vue {
  i18n: Language = useGettext()
  index = 0
  isv4 = false
  phone!: Telephone

  typeMapv3: { [index:string] : string }  = {
    home: this.i18n.$gettext("Home"),
    msg: this.i18n.$gettext("Messages"),
    work: this.i18n.$gettext("Business Phone"),
    pref: this.i18n.$gettext("Main"),
    voice: this.i18n.$gettext("Telephone"),
    fax: this.i18n.$gettext("Fax"),
    cell: this.i18n.$gettext("Mobile"),
    video: this.i18n.$gettext("Videophone"),
    bbs: this.i18n.$gettext("BBS"),
    modem: this.i18n.$gettext("Modem"),
    car: this.i18n.$gettext("Car"),
    isdn: this.i18n.$gettext("ISDN"),
    pcs: this.i18n.$gettext("PCS"),
  }

  typeMapv4: { [index:string] : string } = {
    voice: this.i18n.$gettext("Telephone"),
    cell: this.i18n.$gettext("Mobile"),
    text: this.i18n.$gettext("SMS"),
    fax: this.i18n.$gettext("Fax"),
    video: this.i18n.$gettext("Videophone"),
    pager: this.i18n.$gettext("Pager"),
    textphone: this.i18n.$gettext("Textphone"),
  }

  typeStringforType(types: string[]): string {
    const result: string = this.i18n.$gettext("Telephone")
    if(types.length === 0){
      return result
    }
    const type: string = types[0]
    if(this.typeMapv3.hasOwnProperty(type)){

      return this.typeMapv3[type]
    }

    if(this.typeMapv4.hasOwnProperty(type)) {
      return this.typeMapv4[type]
    }

    return result
  }

  get typeSelectOptions() {
    let result = []
    const typeMap = this.isv4 ? this.typeMapv4 : this.typeMapv3
    for (const [key, value] of Object.entries(typeMap)) {
      result.push({label: value, value: key},)
    }
    return result
  }

  emitDelete(): void {
    this.$emit('delete')
  }

  get value(): string {
    if(!this.phone) return ""

    if(!this.phone.types){
      this.phone.types = []
    }

    return this.phone?.types[0] || ""
  }

  set value(val: string) {
    if(!this.phone) return

    if(!this.phone.types){
      this.phone.types = []
    }

    this.phone.types[0] = val
  }

}
