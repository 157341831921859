<template>
  <div>
    <div class="row">
      <div class="col">
        <span class="p-float-label inline">
          <InputText v-model="name.given" type="text" :disabled="readOnly" />
          <label><translate>Firstname</translate></label>
        </span>
        <span class="p-float-label inline " :class="{ 'mx-2': !isOnMobile, 'my-2': isOnMobile }">
          <Chips
            v-model="name.additional"
            separator=","
            :disabled="readOnly"
            :allow-duplicate="false"
            :add-on-blur="true"
          />
          <label><translate>Middlenames</translate></label>
        </span>
        <span class="p-float-label inline">
          <InputText v-model="name.family" type="text" :disabled="readOnly" />
          <label><translate>Lastname</translate></label>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import StructuredName from "@/model/common/carddav/StructuredName"
import InputText from "primevue/inputtext"
import Chips from "primevue/chips"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  components: {InputText, Chips},
  //@ts-ignore
  props: {
    name: [StructuredName, Object],
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: []
})
export default class StructuredNameField extends Vue {

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }
}
</script>

<style scoped lang="scss">

</style>
