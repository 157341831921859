<template>
  <div class="chat-message-wrapper" @mouseover="hovered = true" @mouseleave="hovered = false">
    <div v-if="isNewDay && messageDate" class="chat-date-hr">
      <hr><div :key="messageDate" class="chat-date-text">
        {{ messageDate }}
      </div><hr>
    </div>
    <div class="chat-message">
      <span v-if="isFollowUpMessage && messageTime" :key="messageTime" class="muted-chat-message-time mr-2">{{ messageTime }}</span>
      <div class="d-flex px-2">
        <div class="chat-avatar-container pr-2 flex-shrink-0" style="position: relative; width: 2.5rem; margin-top: 6px">
          <Avatar
            v-if="!isFollowUpMessage && !isSystemMessage"
            :key="message.userId"
            shape="circle"
            :username="message.userId"
            :class="{'chat-avatar-absolute': !isFollowUpMessage}"
          />
          <Avatar
            v-if="isSystemMessage && !isFollowUpMessage"
            class="chat-system-avatar"
            src="/images/elly_logo.png"
            shape="circle"
          />
        </div>
        <div class="flex-shrink-1 flex-grow-1 pl-2" style="max-width: 100%">
          <div>
            <span v-if="!isFollowUpMessage" :key="messageAuthor" class="chat-message-username">{{ messageAuthor }}</span>
            <span v-if="!isFollowUpMessage && messageTime" :key="messageTime"> &nbsp;<translate>at</translate>&nbsp;<span v-tooltip.right="fullMessageDateTime">{{ messageTime }}</span></span>
            <span v-if="message.edited && !message.deleted && editDateTime" :key="editDateTime" class="text-muted pl-1" style="font-weight: 300; font-size: 96%; color: black;">(<translate>Edited on</translate>&nbsp; {{ editDateTime }})</span>
            <span v-if="message.isForwarded" :key="message.id">&nbsp;<translate>forwarded a message</translate></span>
          </div>
          <div v-if="message.shouldCiteParent" class="chat-message-citation">
            <span :key="message.id"><translate>cited</translate>&nbsp;{{ citedPerson }}: <span v-html="safeRenderCitedMessageText" /> </span>
          </div>
          <div
            :key="message.id"
            class="chat-message-body rendered-markdown"
            :class="{'citation-message': message.isFollowUpInThreadLevel || message.shouldCiteParent, 'text-muted': isSystemMessage || message.deleted}"
            style="font-weight: 300; font-size: 96%; color: black"
            v-html="renderedText"
          />
          <div v-if="shouldDirectlyDisplayImage && !message.deleted">
            <Image :key="message.files[0].thumbnailUrl" :src="message.files[0].thumbnailUrl" :image-class="'chat-message-image'" :preview="true"/>
          </div>
          <div v-if="message.reactions && message.reactions.length > 0 && !message.deleted" :key="message.id" class="chat-message-reaction-container d-flex flex-row">
            <span
              v-for="reaction in message.reactions"
              :key="reaction.emojiName"
              v-tooltip="getTooltipTextForReaction(reaction)"
              class="badge badge-border-dark reaction-badge mr-2"
              @click="removeReaction(reaction)"
              style="padding: .4rem; padding-bottom: .3rem"
            >
              <span style="font-size: 130%">{{ getEmojiForName(reaction.emojiName) }}</span>
              <span class="pl-1">{{ new Set(reaction.userIds || []).size }}</span>
            </span>
          </div>

          <div v-if="!shouldDirectlyDisplayImage && message.files && message.files.length > 0 && !message.deleted" class="chat-message-attached-files-container d-flex">
            <div v-for="attachment in message.files" :key="attachment.id" class="chat-message-file-attachment badge-border-dark p-1 d-flex flex-shrink-1 mr-2">
              <div class="chat-message-attachment-preview mr-2 d-flex flex-column justify-content-center h-100">
                <img v-if="attachment.thumbnailUrl" :src="attachment.thumbnailUrl" class="chat-message-attachment-image">
                <i v-else class="chat-message-attachment-image cil-paperclip" />
              </div>
              <div class="chat-message-attachment-details text-a text-left">
                <p class="small mb-0">
                  {{ formatAttachmentName(attachment.name) }}
                </p>
                <small class="mb-0 text-muted">{{ formatAttachmentSize(attachment.size) }}</small>
              </div>
              <div class="chat-message-attachment-actions ml-2 d-flex flex-column justify-content-center">
                <a
                  v-if="supportsDirectDownload"
                  class="p-2"
                  :href="attachment.downloadUrl"
                  :download="attachment.name"
                >
                  <i class="cil-data-transfer-down" />
                </a>
                <a
                  v-else
                  class="p-2"
                  :href="attachment.downloadUrl"
                  target="_blank"
                >
                  <i class="cil-data-transfer-down" />
                </a>
              </div>
            </div>
          </div>

          <div v-if="message.embeds && message.embeds.length > 0 && !message.deleted" class="chat-message-embed-container pt-2 d-flex flex-column">
            <div v-for="embed in message.embeds" :key="JSON.stringify(embed)" class="chat-message-embed">
              <div v-if="embed.embedType === 'MESSAGE_ATTACHMENT'">
                <div v-for="attachment in message.props['attachments']" :key="JSON.stringify(attachment)" class="chat-message-attachment pt-2 pb-2">
                  <div v-if="meetingLink">
                    <p class="h6 mb-3">
                      {{ message.userId }} <translate>started a meeting</translate>
                    </p>
                    <a class="p-button p-button-primary mr-2" :href="meetingLink" target="_blank"><translate>Join meeting</translate></a>
                    <Button v-if="meetingLink" class="p-button-text" @click="copyMeetingLink">
                      <translate>Copy meeting link</translate>
                    </Button>
                  </div>
                  <div v-else>
                    <!--TODO: Other attachments?-->
                  </div>
                </div>
              </div>
              <div v-if="embed.embedType === 'OPENGRAPH'" class="d-flex flex-row">
                <div v-if="message.images.length > 0" class="message-embed-image">
                  <img :src="message.images[0].url" style="max-width: 6rem" class="pr-2">
                </div>
                <div class="message-embed-body">
                  <a
                    :href="embed.data.url"
                    class="h6 mb-2"
                    target="”_blank”"
                    rel="noopener noreferrer"
                  >
                    {{ embed.data.title }}
                  </a>
                  <p>{{ embed.data.description }}</p>
                </div>
              </div>
              <div v-if="embed.embedType === 'IMAGE'" class="d-flex flex-row">
                <div class="message-embed-image">
                  <img :src="embed.url">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hovered && !message.deleted" :key="message.id" class="chat-message-floating-menu card card-lite z-1">
        <Button
          v-if="!showMoreInFloatingMenu && (canEditPosts || canDeletePosts)"
          v-tooltip.left="i18n.$gettext('More')"
          icon="cil-more"
          class="p-button-text p-button-secondary context-menu-btn"
          @click.stop.prevent="showMoreInFloatingMenu = true"
        />
        <Button
          v-if="showMoreInFloatingMenu && canEditPosts"
          v-tooltip.left="i18n.$gettext('Edit message')"
          icon="cil-pen cil-mirror-icon"
          class="p-button-text context-menu-btn"
          @click.stop.prevent="edit"
        />
        <Button
          v-if="showMoreInFloatingMenu && canDeletePosts"
          v-tooltip.left="i18n.$gettext('Delete message')"
          icon="cil-trash"
          class="p-button-text p-button-danger context-menu-btn"
          :loading="deleteIsLoading"
          @click.stop.prevent="deleteMessage"
        />
        <Button
          v-if="showMoreInFloatingMenu"
          v-tooltip.left="i18n.$gettext('Less')"
          icon="cil-more cil-mirror-icon"
          class="p-button-text p-button-secondary context-menu-btn"
          @click.stop.prevent="showMoreInFloatingMenu = false"
        />
        <Button
          v-tooltip.left="i18n.$gettext('Reply to message')"
          icon="cil-share cil-mirror-icon"
          class="p-button-text p-button-secondary context-menu-btn"
          @click.stop.prevent="reply"
        />
        <Button
          v-tooltip.left="i18n.$gettext('Forward message')"
          icon="cil-share"
          class="p-button-text p-button-secondary context-menu-btn"
          @click.stop.prevent="forward"
        />
        <Button
          v-if="canAddReaction"
          v-tooltip.left="i18n.$gettext('React to message')"
          v-emoji="addReaction"
          icon="cil-smile-plus"
          class="p-button-text p-button-secondary context-menu-btn"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import Message from "@/model/entry/Message"
import Avatar from "@/components/common/Avatar.vue"
import dayjs from "@/util/dayjs"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {replaceColonsWithUTF8, replaceUTF8WithColons} from "@/util/emojiFromStringConverter"
import {messageServiceApi} from "@/api/MessageServiceApi"
import MessageReaction from "@/model/entry/MessageReaction"
import {rpcClient} from '@/api/WebsocketClient'
import RpcError from "@/api/RpcError"
import Formats from "@/util/formats"
import ShareModal from "@/components/filemanger/subcomponents/ShareModal.vue"
import featureDetect from "@/util/FeatureDetect"
import Button from "primevue/button"
import Textarea from "primevue/textarea"
import Dialog from "primevue/dialog"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import ChatUtil from "@/util/ChatUtil"
import Image from "primevue/image"

const max_attachment_name_length = 24

@Options({
  components: {ShareModal, Avatar, Button, Textarea, Dialog, Image},
  //@ts-ignore
  props: {
    message: [Message, Object],
    canAddReaction: Boolean,
    canRemoveReaction: Boolean,
    canDeletePosts: Boolean,
    canEditPosts: Boolean
  },
  emits: [ 'reply', 'edit', 'forward' ]
})
export default class ChatMessage extends Vue {

  message!: Message
  canAddReaction!: boolean
  canRemoveReaction!: boolean
  canDeletePosts!: boolean
  canEditPosts!: boolean

  i18n: Language = useGettext()
  messageApi = messageServiceApi
  rpcClient = rpcClient
  toast = useToast()
  confirm = useConfirm()

  showEditModal = false
  showMoreInFloatingMenu = false
  deleteIsLoading = false
  hovered = false

  get users(): Map<string, User> {
    const users: User[] = userServiceApi.getUsers().data || []
    const map: Map<string, User> = new Map<string, User>()
    users.forEach((u: User) => {
      if (u.userName) map.set(u.userName, u)
    })
    return map
  }

  get citedPerson(): string {
    let message: Message | null  = this.citedMessage
    if (!message || message.userId === null) return ""
    const user = this.users.get(message.userId)
    if (!user || !user.displayName) return message.userId
    return user.displayName
  }

  get safeRenderCitedMessageText(): string {
    let message: Message | null  = this.citedMessage
    if (!message ) return ""
    let content = ChatUtil.safeRenderMessageText(message.text, true, this.isSystemMessage, true)
    return content //TODO: Maybe shorten this?
  }

  get citedMessage(): Message | null {
    return this.message.parentMessage
  }

  get isSystemMessage(): boolean {
    if (!this.message) return false
    return this.message.type !== "DEFAULT"
  }

  get isNewDay(): boolean {
    return this.message.isFirstMessageOfTheDay || false
  }

  get messageDate(): string | null {
    return this.message.created ? dayjs(this.message.created).format("dddd, D. MMMM") : null
  }

  get isFollowUpMessage(): boolean {
    return this.message.isFollowup || false
  }

  get messageTime(): string | null {
    return this.message.created ? dayjs(this.message.created).format("HH:mm") : null
  }

  get fullMessageDateTime(): string | null {
    return this.message.created ? dayjs(this.message.created).format("dddd, D. MMMM, YYYY HH:mm") : null
  }

  get editDateTime(): string | null {
    return this.message.edited ? dayjs(this.message.edited).format("DD. MM. YYYY, HH:mm") : null
  }

  get deleteDateTime(): string | null {
    return this.message.deleted ? dayjs(this.message.deleted).format("DD. MM. YYYY, HH:mm") : null
  }

  get messageAuthor(): string {
    if (this.isSystemMessage) return this.i18n.$gettext("SYSTEM")
    if (!this.message.userId) return ""
    const user = this.users.get(this.message.userId)
    if (!user || !user.displayName) return this.message.userId
    return user.displayName
  }

  get renderedText(): string {
    if (this.message.deleted) {
      return this.i18n.$gettext("Deleted Message") + (this.deleteDateTime ? " (" + this.deleteDateTime + ")" : '')
    } else {
      return ChatUtil.safeRenderMessageText(this.message.text, false, this.isSystemMessage, true)
    }
  }

  get shouldDirectlyDisplayImage() {
    return this.message.files ? (this.message.files.length === 1 && ["jpeg", "jpg", "png", "svg"].includes(this.message.files[0].extension || "")): false
  }

  get hasAttachedFiles(): boolean {
    return this.message.files ? this.message.files.length > 0 : false
  }

  get supportsDirectDownload(): boolean {
    return featureDetect.supportsNativeDownloadAttribute
  }

  get hasLinkedFile(): boolean {
    if (!this.message.props) return false
    return this.message.props.hasOwnProperty("linkedfile")
  }

  getEmojiForName(name: string) {
    const emojiWithColons = `:${name}:`
    return replaceColonsWithUTF8(emojiWithColons)
  }

  formatAttachmentName(name: string) {
    if (name.length > max_attachment_name_length) {
      return name.substr(0, max_attachment_name_length) + "..."
    } else {
      return name
    }
  }

  formatAttachmentSize(size: number) {
    return Formats.formatBytes(size)
  }

  addReaction(emoji: string) {
    let userName = this.rpcClient.session.user?.userName
    const emojiName: string = replaceUTF8WithColons(emoji).replaceAll(":", "")
    let alreadyReactedLikeThis = false
    //Check if necessary:
    this.message.reactions?.forEach((reaction: MessageReaction) => {
      if (reaction.emojiName === emojiName && userName) {
        if (reaction.userIds && reaction.userIds.indexOf(userName) > -1) {
          alreadyReactedLikeThis = true
        }
      }
    })
    if (!this.message.id || alreadyReactedLikeThis) return
    this.messageApi._addReaction(this.message.id, emojiName).then(() => {
      this.message.reactions?.forEach((reaction: MessageReaction) => {
        if (reaction.emojiName === emojiName && userName) {
          reaction.userIds?.push(userName) //We made sure above that we do not react a second time!
        }
      })

    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not add reaction"))
    })
  }

  removeReaction(reaction: MessageReaction) {
    if (this.canRemoveReaction) {
      let thisUsername = this.rpcClient.session.user?.userName
      if (!reaction.userIds || !thisUsername || !this.message.id || !reaction.emojiName || reaction.userIds?.indexOf(thisUsername) === -1 ) {
        return //This user has not reacted to this message or something else went wrong
      }

      this.messageApi._removeReaction(this.message.id, reaction.emojiName).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to remove reaction"))
      })
    }
  }

  getTooltipTextForReaction(reaction: MessageReaction) {
    const username = this.rpcClient.session.user?.userName
    let otherUsers = [...( reaction.userIds || [])]
    let isInUsers: number = ( !!username ? otherUsers.indexOf(username) : -1)

    if (isInUsers == 0 && reaction.userIds?.length == 1) {
      return this.i18n.$gettext("You reacted. (Click to remove)")
    }

    if (isInUsers > -1) {
      otherUsers.splice(isInUsers, 1)
    }

    let text: string = otherUsers.join(", ")
    if (isInUsers > -1) {
      text += " " + this.i18n.$gettext("and you. (Click to remove)")
    }
    return text
  }

  reply() {
    this.$emit('reply', this.message.rootId || this.message.id)
  }

  forward() {
    this.$emit('forward', Object.assign(new Message(), this.message))
  }

  edit() {
    this.$emit('edit', Object.assign(new Message(), this.message))
  }

  deleteMessage() {
    this.confirm.require({
      message: this.i18n.$gettext("Do you want to delete this message"),
      header: this.i18n.$gettext("Are you sure?"),
      icon: 'cil-warning',
      accept: () => {
        if (!this.message?.id) {
          return
        }
        this.deleteIsLoading  = true
        messageServiceApi._deleteMessage(this.message.id).then(() => {
          this.toast.success(this.i18n.$gettext("Message deleted"))
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Message could not be deleted"))
        }).finally(() => {
          this.deleteIsLoading = false
        })
        //callback to execute when user confirms the action
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  get meetingLink(): string | null {
    return this.message.props?.meeting_link
  }

  copyMeetingLink() {
    if (this.meetingLink) {
      const el = document.createElement('textarea')
      el.value = this.meetingLink
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.toast.success(this.i18n.$gettext('Link copied to clipboard.'))
    } else {
      this.toast.error(this.i18n.$gettext('Could not copy link to clipboard.'))
    }
  }
}
</script>

<style lang="scss">

@import "node_modules/elly-bs4/sass/variables";

.chat-message-wrapper {
  border-bottom: none;
}

.chat-message {
  position: relative;

  .chat-message-floating-menu {
    position: absolute;
    top: 2px;
    display: none;
    right: 1em;
    z-index: 1000;
    transition: width 2000ms ease-in-out;
  }

  .reply-menu {
    right: 4rem;
  }

  .emoji-menu {
    right: 1rem;
  }

  &:hover {
    background-color: $uniki_secondary !important;

    & .muted-chat-message-time {
      display: inline;
    }

    .chat-message-floating-menu {
      display: block;
    }
  }
}

.list-item:last-child {
  .chat-message-floating-menu {
    top: -1.2rem !important;
  }
}

.chat-message-file-attachment {
  display: inline-block;
}

.chat-message-attachment-preview {
  heigt: 100%;

  & > .chat-message-attachment-image {
    width: 2rem;
    height: 2rem;
    object-fit: cover;
    font-size: 2rem;
  }
}

.chat-message-last {
  padding-bottom: 2rem;
}

.citation-message {
  padding-left: 1rem;
  border-left: 2px solid $uniki_primary;
}

.chat-message-citation {
  min-height: $font-size-base * $line-height-base;
}

.muted-chat-message-time {
  position: absolute;
  top: 10%;
  left: 1rem;

  display: none;
  font-size: 0.8rem;
  color: #47BBC7;
}

.chat-avatar-container {
  width: 2.5rem;
  position: relative;
}

.chat-avatar-absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.chat-system-avatar {
}

.chat-message-username {
  font-weight: 600;
  color: #1c1b3c;
}

.chat-message-embed {
  border-left: 2px solid $uniki_primary;
  padding-left: 1rem;
}

.chat-message-image {
  max-height: 400px;
  max-width: 100%;

  & > img {
      max-height: 400px;
      max-width: 100%;
  }
}

$chat-date-hr-color: #ced4da;

.chat-date-hr {
  postition: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  hr {
    flex-grow: 1;
    border-top: 1px solid $chat-date-hr-color !important;
  }

  .chat-date-text {
    //margin-right: 1.2rem;
    //margin-left: 1.2rem;
    padding: 0.25rem 1rem;
    height: 2.25rem;
    //border-top: 1px solid $chat-date-hr-color;
    //border-bottom: 1px solid $chat-date-hr-color;
    border: 1px solid $chat-date-hr-color;
    border-radius: 3px;
    position: relative;
    color: #adb5bd;


    /*
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -0.88rem;
      top: 0.23rem;
      border-right: 1px solid $chat-date-hr-color;
      border-top: 1px solid $chat-date-hr-color;
      height: 1.75rem;
      width: 1.75rem;
      transform: rotate(-135deg);
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -0.88rem;
      top: 0.23rem;
      border-right: 1px solid $chat-date-hr-color;
      border-top: 1px solid $chat-date-hr-color;
      height: 1.75rem;
      width: 1.75rem;
      transform: rotate(45deg);
    }
     */

  }
}

.badge-border-dark {
  border: 1px solid $uniki_primary_dark;
  background-color: $white;
  border-radius: 3px;
}

.reaction-badge {
  cursor: pointer;

  &:hover {
    background-color: $uniki_secondary;
  }
}

.chat-message-wrapper {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.chat-message-body pre {
  margin-right: 0 !important;
  max-width: calc(100% - 35px);
  overflow: auto;
}

.chat-message-body {
  table,thead,th,td,tr {
    border-collapse: collapse;
    border: 1px solid silver;
    padding: .25rem;
  }
}

span.emoji-native {
  font-size: 150%;
}

span.emoji-large {
  font-size: 300%;
}

span.emoji-native {
  font-size: 150%;
}

span.emoji-large {
  font-size: 300%;
}

</style>
