

import {Options, Vue} from "vue-class-component"
import StructuredName from "@/model/common/carddav/StructuredName"
import InputText from "primevue/inputtext"
import Chips from "primevue/chips"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  components: {InputText, Chips},
  //@ts-ignore
  props: {
    name: [StructuredName, Object],
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: []
})
export default class StructuredNameField extends Vue {

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }
}
