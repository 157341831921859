import GeneratedEmailStore from "./generated/GeneratedEmailStore"
import Email from "@/model/entry/Email"

class EmailStore extends GeneratedEmailStore {

    addOrReplaceEmail(newEmail: Email) {
        if (newEmail.originalId) {
            const oldEmail: Email | undefined = this.state.emails.get(newEmail.originalId)
            if (oldEmail && !oldEmail.isPreview && newEmail.isPreview) { //Only update flags
                oldEmail.seen = newEmail.seen
                oldEmail.systemFlags = newEmail.systemFlags
                oldEmail.userFlags = newEmail.userFlags
            } else {
                this.state.emails.set(newEmail.originalId, newEmail)
            }
        }
    }
}

export const emailStore = new EmailStore()
