<template>
  <div style="display: inline-block" class="">
    <span class="p-float-label inline mr-2">
      <InputText v-model="company" type="text" :disabled="readOnly" />
      <label><translate>Company</translate></label>
    </span>
    <span class="p-float-label inline mr-2">
      <Chips
        v-model="departments"
        separator=","
        :disabled="readOnly"
        :add-on-blur="true"
      />
      <label><translate>Departments</translate></label>
    </span>
    <Button
      v-if="!readOnly"
      icon="cil-trash-alt"
      class="p-button-danger after-inline-label"
      @click="emitDelete"
    />
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import Organization from "@/model/common/carddav/Organization"
import InputText from "primevue/inputtext"
import Chips from "primevue/chips"
import Button from "primevue/button"

@Options({
  components: {InputText, Chips, Button},
  //@ts-ignore
  props: {
    organization: [Organization, Object],
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'delete' ]
})
export default class OrganizationField extends Vue {

  organization!: Organization;

  get company(): string {
    if(!this.organization || !this.organization.titles) return ""
    return this.organization.titles[0] || ""
  }

  set company(c: string) {
    if(!this.organization || !this.organization.titles) return
    this.organization.titles[0] = c
  }

  get departments(): string[] {
    if(!this.organization) return []
    return this.organization.titles?.slice(1) || []
  }

  set departments(d: string[]) {
    if(!this.organization || !this.organization.titles ) return
    this.organization.titles = [(this.organization.titles[0] || ""), ...d]
  }

  emitDelete(): void {
    this.$emit('delete')
  }

}
</script>

<style scoped lang="scss">

</style>
