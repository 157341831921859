<template>
  <div v-if="items.length > 0" class="p-menu p-component" style="width: auto">
    <ul class="p-menu-list p-reset">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="p-menuitem"
        :class="{ 'is-selected': index === selectedIndex }"
        @click="selectItem(index)"
      >
        <a class="p-menuitem-link" role="menuitem" tabindex="0">
          {{ item.givenname }} {{ item.surname }} ({{ item.cn }})
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {Watch} from "vue-property-decorator"

@Options({
  components: {},
  //@ts-ignore
  props: {
    items: {
      type: Array,
      required: true,
    },
    command: {
      type: Function,
      required: true,
    },
  },
  emits: []
})
export default class MentionList extends Vue {

  selectedIndex: number = 0
  items: any[] = []
  command: (p: {id: any}) => void = (p) => {}

  @Watch('items')
  watchItems(){
    this.selectedIndex = 0
  }

  onKeyDown({ event }: {event: KeyboardEvent}) {
    if (event.key === 'ArrowUp') {
      this.upHandler()
      return true
    }
    if (event.key === 'ArrowDown') {
      this.downHandler()
      return true
    }
    if (event.key === 'Enter') {
      this.enterHandler()
      return true
    }
    return false
  }

  upHandler() {
    this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
  }

  downHandler() {
    this.selectedIndex = (this.selectedIndex + 1) % this.items.length
  }

  enterHandler() {
    this.selectItem(this.selectedIndex)
  }

  selectItem(index: number) {
    const completeUsername = this.items[index].cn
    const namePart = completeUsername.split("@")[0] || completeUsername
    if (namePart) {
      this.command({ id: namePart })
    }
  }
}
</script>

<style lang="scss" scoped>

.items {
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: #FFF;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;
  &.is-selected {
    border-color: #000;
  }
}

</style>
