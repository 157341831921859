

import {Options, Vue} from "vue-class-component"
import {contactServiceApi} from "@/api/ContactServiceApi"
import Contact from "@/model/entry/Contact"
import AddressBook from "@/model/directory/AddressBook"
import InfiniteList from "@/components/common/InfiniteList.vue"
import ProgressBar from "primevue/progressbar"
import ContactListItem from "@/components/contacts/subcomponents/ContactListItem.vue"
import ContactEditor from "@/components/contacts/subcomponents/ContactEditor.vue"
import Button from "primevue/button"
import ContextMenu from "primevue/contextmenu"
import Skeleton from "primevue/skeleton"
import InputText from "primevue/inputtext"
import breakpointUtil from "@/util/BreakpointUtil"
import Dialog from "primevue/dialog"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {addressBookServiceApi} from "@/api/AddressBookServiceApi"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import Listbox from "primevue/listbox"
import {contactStore} from "@/store/ContactStore"
import Query from "@/model/common/Query"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"


@Options({
  components: {
    Dialog, ContactEditor, ContactListItem, InfiniteList, ProgressBar, Button, ContextMenu, Skeleton, InputText, Listbox
  },
  //@ts-ignore
  props: {
    addressBook: [ AddressBook, Object ],
    searchQuery: {
      type: [ Query, Object ],
      default: null
    }
  },
  emits: []
})
export default class ContactsList extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  addressBook: AddressBook | null = null
  searchQuery!: Query | null
  loading = false
  contactsFilter: string =''
  showModalOnMobile: boolean  = false

  contactToMove: Contact | null = null
  moveTarget: string | null = null
  moveLoading = false

  get isOnMobile() {
    return breakpointUtil.isOnMobile()
  }

  get isAllowedToEdit() {
    return ['WRITE', 'OWNER'].includes(this.addressBook?.shareAccess || '')
  }

  get contacts(): Contact[] {
    let swr = null
    if (this.addressBook?.originalId && this.searchQuery) {
      swr = contactServiceApi.queryContacts(this.searchQuery)
      swr.call?.promise?.finally(() => {
        this.loading = false
      })
    } else if (this.addressBook?.originalId) {
      swr = contactServiceApi.getAllContacts(this.addressBook.originalId, 1000, [ 'structuredName.family:asc' ])
      swr.call?.promise?.then((ids: string[]) => {
        //Replace the state, to make sure no deleted contacts remain in it
        const contacts = [...contactStore.state.contacts.values()]
        contactStore.replaceContacts(contacts.filter(contact => (contact.originalParentId !== this.addressBook?.originalId) || ids.includes(contact.originalId || '')))
      }).finally(() => {
        this.loading = false
      })
    } else {
      return []
    }

    this.loading = swr.call?.loading || false
    if (swr.data) {
      swr.data.forEach(contact => (contact.originalParentId = this.addressBook?.originalId || contact.originalParentId))
    }
    let data = swr.data || []
    if (this.contactsFilter) {
      data = data.filter(c => {
        const lowerName: string | undefined = c.formattedName?.text?.toLowerCase()
        return lowerName ? lowerName.split(/\s+/).find(s => s.startsWith(this.contactsFilter.toLowerCase())) : false
      })
    }
    if (this.searchQuery) {
      SortAndFilterUtil.sort(data, [ 'structuredName.family:asc' ])
    }
    return data
  }

  get hasNoContent(): boolean {
    return this.contacts.length === 0
  }

  get selectedContact(){
    const selectedId = this.$route.params.contact as string
    if (!selectedId) return false
    return contactServiceApi.getContact(selectedId)
  }

  get hasSelectedContact(): boolean{
    return !!this.selectedContact
  }

  handleContactSelect(contact: Contact): void {
    if (this.isOnMobile) this.showModalOnMobile = true
    const newPath = "/contacts/" + this.addressBook?.originalId + "/" + contact.originalId
    void this.$router.push({path: newPath})
  }

  handleReturnToAdressBook(): void {
    const newPath = "/contacts/" + this.addressBook?.originalId
    void this.$router.push({path: newPath})
  }

  get modalStyle(){
    if (breakpointUtil.isOnMobile()) {
      return { width: "100%", margin: "0", height: "100% !important", maxHeight: "100%" }
    } else {
      return { width: "50%", margin: "1rem", height: "100%" }
    }
  }

  openMoveModal(contact: Contact | null) {
    this.contactToMove = contact
  }

  get showMoveModal(): boolean {
    return !!this.contactToMove
  }

  set showMoveModal(value: boolean) {
    if (!value) {
      this.moveTarget = null
      this.contactToMove = null
    }
  }

  get availableAdressbookOptions(): AddressBook[] {
    return  (addressBookServiceApi.getAddressBooks().data || []).filter(book => {
      return Boolean(book.originalId && book.name && book.originalId !== this.contactToMove?.originalParentId)
    })
  }

  moveContact() {
    console.dir(this.addressBook)
    console.dir(this.moveTarget)
    console.dir(this.contactToMove)
    if (this.addressBook?.originalId && this.moveTarget && this.contactToMove?.originalId) {
      this.moveLoading = true
      contactServiceApi._moveContact(this.contactToMove.originalId, this.moveTarget).then(() => {
        this.toast.success(this.i18n.$gettext("Contact moved"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Could not move contact"))
      }).finally(() => {
        this.moveLoading = false
        this.showMoveModal = false
      })
    }
  }
}
