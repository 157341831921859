<template>
  <div v-if="[ 'LOCAL_FILE', 'LOCAL_MOUNT' ].includes(dataSource.authenticationType)" class="wizard-content">
    <div v-if="[ 'pst' ].includes(dataSource.name)" class="mb-4">
      <Dropdown
          v-model="localImportSource"
          :options="localImportSourceOptions"
          option-label="label"
          option-value="value"
          :label="i18n.$gettext('File Location')">
      </Dropdown>
    </div>
    <div v-if="localImportSource === 'usb'" class="wizard-content">
      <ProgressBar v-if="usbFileSystems === null" mode="indeterminate"/>
      <div v-else class="wizard-content">
        <div class="d-flex align-items-center w-100 mb-4">
          <Dropdown
              class="flex-grow-1 mr-2"
              v-model="selectedUSBFileSystem"
              :options="usbFileSystems"
              :multiple="false"
              :filter="false"
              option-label="name"
              @update:modelValue="mountDiskAndSave"
              :label="i18n.$gettext('Select USB drive')"
          />
          <LoadingButton variant="primary" :action="reloadDisks"><translate>Reload</translate></LoadingButton>
        </div>
        <div v-if="selectedUSBFileSystem && selectedUSBDataSourceInstance && localMountFileTree" class="mt-4 wizard-content">
          <p class="mb-0"><translate>Select the file you want to import.</translate></p>
          <Tree
              class="wizard-content"
              v-model:selection-keys="selectedFileFromUSB"
              :value="localMountFileTree || []"
              :loading="!localMountFileTree"
              @node-expand="onUSBFileNodeExpand"
              selection-mode="single"
              @update:selectionKeys="selectFileAndSave"
          >
            <template #default="slotProps">
              <div class="flex-row">
                {{ slotProps.node.label }}
              </div>
            </template>
          </Tree>
        </div>
        <Skeleton v-else-if="selectedUSBFileSystem && dataSource.authenticationType === 'LOCAL_FILE'" class="w-100 mt-4" height="40px" style="width: 80%" />
      </div>
    </div>
    <div v-else-if="localImportSource === 'upload'" class="wizard-content">
      <div v-if="[ 'pst' ].includes(dataSource.name)" class="wizard-content">
        <p><translate>Click "Upload new file" to upload a PST file for import. The maximum file size is 4GB. Larger PST files can be imported from a USB disk.</translate></p>
        <Skeleton v-if="pstFileDataSourceInstances === null" class="mt-6" height="40px" style="width: 80%" />
        <Listbox
            v-else
            class="wizard-content"
            v-model="selectedPstFileDataSourceInstance"
            :options="pstFileDataSourceInstances"
            :multiple="false"
            :filter="false"
            @update:modelValue="handlePstFileDataSourceInstanceSelection"
        >
          <template #option="slotProps">
            <div v-if="slotProps.option.displayName.includes('&' + 'plus;')">
              <span v-if="uploadingFileProgress === null" v-html="slotProps.option.displayName" class="text-primary"></span>
              <ProgressBar v-else :value="uploadingFileProgress" :show-value="false"></ProgressBar>
            </div>
            <div v-else class="flex-row">
              <i :class="slotProps.option.icon" />
              {{ slotProps.option.displayName }}
            </div>
            <input
                ref="pstfileinput"
                type="file"
                accept=".pst"
                style="display: none"
                @change="loadPSTFile"
            >
          </template>
        </Listbox>
      </div>
    </div>
  </div>
  <div v-else-if="dataSource.authenticationType === 'API_OAUTH'">
    <div class="col-12" v-if="!waitingForOauthConfirmation">
      <p><translate :translate-params="{ service: dataSource.displayName }">Click the button and sign in to your %{ service } account.</translate></p>
      <LoadingButton class="mt-4" variant="primary" :action="createEmptyOAuthSourceAndVerify"><translate :translate-params="{ service: dataSource.displayName }">Sign in at %{ service }</translate></LoadingButton>
    </div>
    <div class="col-12" v-else>
      <p><translate :translate-params="{ service: dataSource.displayName }">Please sign in to your %{ service } account in the popup window. If there is no popup window, make sure you this site to open popup windows in your browser. After confirmation you will be redirected automatically.</translate></p>
      <ProgressBar mode="indeterminate"/>
    </div>
  </div>
  <div v-else>
    <p class="mb-2" v-if="[ 'webdav', 'caldav', 'carddav' ].includes(dataSource.name)" :key="dataSource.name">
      <translate :translate-params="{ service: dataSource.displayName }">
        Please enter the web address and login data for your %{ service } account:
      </translate>
    </p>
    <p class="mb-2" v-else-if="[ 'ftp', 'sftp', 'smb' ].includes(dataSource.name)" :key="dataSource.name">
      <translate :translate-params="{ service: dataSource.displayName }">
        Please enter the server address and login data for your %{ service } account:
      </translate>
    </p>
    <p class="mb-2" v-else-if="[ 'imap' ].includes(dataSource.name)" :key="dataSource.name">
      <translate :translate-params="{ service: dataSource.displayName }">
        Please enter the mail server host/domain, port and encryption method for your %{ service } account::
      </translate>
    </p>
    <div class="row mb-3" v-if="[ 'webdav', 'caldav', 'carddav' ].includes(dataSource.name)" :key="dataSource.name">
      <div class="col-12">
        <AnimatedInput :label="i18n.$gettext('URL')" v-model="url" class="w-100 mb-3" />
      </div>
    </div>
    <div class="row mb-3" v-else-if="[ 'sftp', 'smb' ].includes(dataSource.name)" :key="dataSource.name">
      <div :class="{ 'col-9': dataSource.name !== 'smb', 'col-12 col-md-6': dataSource.name === 'smb' }">
        <AnimatedInput :label="i18n.$gettext('Server Address')" v-model="host" class="w-100 mb-3" />
      </div>
      <div class="col-3" v-if="dataSource.name !== 'smb'">
        <AnimatedInput :label="i18n.$gettext('Port')" type="number" v-model="port" class="w-100" />
      </div>
      <div class="col-12 col-md-6" v-else>
        <AnimatedInput :label="i18n.$gettext('Domain (optional)')" v-model="domain" class="w-100" />
      </div>
    </div>
    <div class="row mb-3" v-else-if="[ 'ftp', 'imap' ].includes(dataSource.name)" :key="dataSource.name">
      <div class="col-12 col-md-6">
        <AnimatedInput :label="i18n.$gettext('Server')" v-model="host" class="w-100 mb-3" />
      </div>
      <div class="col-6 col-md-3">
        <AnimatedInput :label="i18n.$gettext('Port')" type="number" v-model="port" class="w-100" />
      </div>
      <div class="col-6 col-md-3">
        <Dropdown
            v-model="encryption"
            :options="encryptionOptions"
            option-label="label"
            option-value="value"
            :label="i18n.$gettext('Encryption')">
        </Dropdown>
      </div>
    </div>
    <p class="mb-2"><translate>Please enter user name and password:</translate></p>
    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <AnimatedInput :label="i18n.$gettext('Username')" v-model="userName" class="w-100" />
      </div>
      <div class="col-12 col-md-6">
        <AnimatedInput :label="i18n.$gettext('Password')" type="password" v-model="password" class="w-100" />
      </div>
    </div>
    <p><translate :translate-params="{ service: dataSource.displayName }">Click "Test & Save" below to add the %{ service } account as a new data source. This will test the login, so please ensure that the entered data is correct.</translate></p>
    <div class="d-flex justify-content-end">
      <LoadingButton class="ml-auto" variant="primary" :action="createAndVerifyDirectAPI"><translate>Test & Save</translate></LoadingButton>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {Watch} from "vue-property-decorator"
import DataSourceInstance from "@/model/DataSourceInstance"
import {dataSourceServiceApi} from "@/api/DataSourceServiceApi"
import useToast from "@/util/toasts"
import {useRouter} from "vue-router"
import {dataSourceInstanceStore} from "@/store/DataSourceInstanceStore"
import DataSource from "@/model/DataSource"
import ProgressBar from "primevue/progressbar"
import {dataImportServiceApi} from "@/api/DataImportServiceApi"
import USBStoragePartition from "@/model/adminpanel/backup/USBStoragePartition"
import Listbox from "primevue/listbox"
import Dropdown from "@/components/common/Dropdown.vue"
import {rpcClient} from "@/api/WebsocketClient"
import SWR from "@/api/SWR"
import RpcError from "@/api/RpcError"
import {ref} from "@vue/reactivity"
import Skeleton from "primevue/skeleton"
import JsUiTreeModel from "@/model/JsUiTreeModel"
import Tree from "primevue/tree"

@Options({
  components: {
    AnimatedInput, LoadingButton, ProgressBar, Listbox, Dropdown, Skeleton, Tree
  },
  //@ts-ignore
  props: {
    visible: Boolean,
    dataSource: [ DataSource, Object ],
    displayName: String
  },
  emits: [
      'created'
  ]
})
export default class DataSourceInstanceForm extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  router = useRouter()
  rpcClient = rpcClient

  dataSource!: DataSource
  displayName!: string
  usbFileSystems: { name: string, id: string }[] | null = null
  selectedUSBFileSystem: { name: string, id: string } | null = null

  selectedUSBDataSourceInstance: DataSourceInstance | null = null
  localMountFileTree: JsUiTreeModel[] | null = null
  selectedFileFromUSB: any | null = null

  selectedPstFileDataSourceInstance: DataSourceInstance | null = null
  //@ts-ignore
  pstfileinput: HTMLInputElement = ref<HTMLInputElement | null>(null)
  uploadingFileProgress: number | null = null

  localImportSource: string = 'usb'
  localImportSourceOptions: { label: string, value: string }[] = [
    {
      label: this.i18n.$gettext('Load file from USB drive'),
      value: 'usb'
    },
    {
      label: this.i18n.$gettext('Use uploaded file'),
      value: 'upload'
    }
  ]

  encryption: string = 'NONE'
  imapEncryptionOptions: { label: string, value: string }[] = [
    {
      label: this.i18n.$gettext('SSL/TLS'),
      value: 'SSL'
    },
    {
      label: this.i18n.$gettext('STARTTLS'),
      value: 'STARTTLS'
    },
    {
      label: this.i18n.$gettext('None'),
      value: 'NONE'
    }
  ]
  ftpEncryptionOptions: { label: string, value: string }[] = [
    {
      label: this.i18n.$gettext('Implicit'),
      value: 'SSL'
    },
    {
      label: this.i18n.$gettext('Explicit'),
      value: 'STARTTLS'
    },
    {
      label: this.i18n.$gettext('None'),
      value: 'NONE'
    }
  ]

  userName: string = ''
  password: string = ''
  domain: string = ''
  url: string = ''
  host: string = ''
  port: number = 0

  waitingForOauthConfirmation: boolean = false

  get encryptionOptions() {
    if (this.dataSource.name === 'imap') {
      return this.imapEncryptionOptions
    } else if (this.dataSource.name === 'ftp') {
      return this.ftpEncryptionOptions
    }
    return []
  }

  get pstFileDataSourceInstances(): DataSourceInstance[] | null {
    const allInstancesSWR: SWR<DataSourceInstance[], number[]> = dataSourceServiceApi.getDataSourceInstances()
    if (allInstancesSWR.data !== null) {
      const instances: DataSourceInstance[] = [
        Object.assign(new DataSourceInstance(), {
          displayName: '&plus; ' + this.i18n.$gettext('Upload new file')
        })
      ]
      instances.push(...allInstancesSWR.data.filter(instance => {
        return instance.datasourceName === 'pst' && !!instance.authenticationDetails?.file
      }))
      return instances
    }
    return null
  }

  @Watch('visible')
  resetIfNotVisible(visible: boolean) {
    if (!visible) {
      this.userName = ''
      this.password = ''
      this.url = ''
      this.host = ''
      this.port = 0
    }
  }

  @Watch("selectedUSBDataSourceInstance")
  async loadSourceTree() {
    if (!this.selectedUSBDataSourceInstance?.id) return false
    try {
      this.localMountFileTree = null
      this.localMountFileTree = await dataSourceServiceApi._getDirectoryTreeOfSourceInstance(this.selectedUSBDataSourceInstance.id, '/')
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not fetch source directories"))
    }
  }

  async onUSBFileNodeExpand(node: JsUiTreeModel) {
    if (this.selectedUSBDataSourceInstance?.id && node.key) {
      node.children = await dataSourceServiceApi._getDirectoryTreeOfSourceInstance(this.selectedUSBDataSourceInstance.id, node.key)
    }
  }

  loadPSTFile(event: any): void {
    // Reference to the DOM input element
    const {files} = event.target
    // Ensure that you have a file before attempting to read it
    if (files && files[0]) {
      if (files[0].size > 4294967296) {
        this.toast.error(this.i18n.$gettext("The chosen file exceeds the maximum upload size of 4GB. Please load the file from a USB drive."))
        return
      }
      let data = new FormData()
      data.append("file", files[0])
      const client = rpcClient.getAjaxClient()
      this.uploadingFileProgress = 0
      client.request({
        method: "post",
        url: 'pstupload',
        headers: {"Content-Type": "multipart/form-data"},
        data: data,
        onUploadProgress: (p: ProgressEvent) => {
          this.uploadingFileProgress = Math.round((p.loaded / p.total) * 100)
        }
      }).then(() => {
        dataSourceServiceApi.getDataSourceInstances(true)
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to upload PST file."))
      }).finally(() => {
        this.uploadingFileProgress = null
      })
    }
  }

  handlePstFileDataSourceInstanceSelection(target: DataSourceInstance) {
    if (target?.displayName?.startsWith('&' + 'plus;')) {
      this.pstfileinput.click()
      this.selectedPstFileDataSourceInstance = null
    } else {
      this.$emit('created', target)
    }
  }

  async reloadDisks() {
    this.usbFileSystems = null
    return dataImportServiceApi._getUSBStorageDrives().then((usbDrives: any[]) => {
      const disksAndPartitions: { name: string, id: string }[] = []
      usbDrives.forEach((disk: any) => {
        if (disk.partitions) {
          for (let i = 0; i < disk.partitions.length; i++) {
            const partition: USBStoragePartition = disk.partitions[i] as USBStoragePartition
            if (partition.fsUUID && !disksAndPartitions.find(part => part.id === partition.fsUUID)) {
              const name: string = (disk.model || disk.serial || '') + ' Partition ' + (i + 1)
              disksAndPartitions.push({ name: name, id: partition.fsUUID })
            }
          }
        }
      })
      this.usbFileSystems = disksAndPartitions
    })
  }

  async mountDiskAndSave() {
    if (this.selectedUSBFileSystem?.name) try {
      this.selectedUSBDataSourceInstance = null
      const newInstance: DataSourceInstance = new DataSourceInstance()
      newInstance.datasourceName = this.dataSource.name
      newInstance.displayName = this.selectedUSBFileSystem.name

      newInstance.authenticationDetails = {
        fsUUID: this.selectedUSBFileSystem.id
      }

      const id: number = await dataSourceServiceApi._createDataSourceInstance(newInstance)
      const savedInstance = dataSourceInstanceStore.state.dataSourceInstances.get(id)
      if (savedInstance) {
        if (this.dataSource.authenticationType == 'LOCAL_FILE') {
          this.selectedUSBDataSourceInstance = savedInstance
        } else {
          this.$emit('created', savedInstance)
          this.resetIfNotVisible(false)
        }
      }
    } catch (e) {
      this.toast.error(e.message, this.i18n.$gettext("The data from the disk could not be read."))
      this.selectedUSBFileSystem = null
    }
  }

  async selectFileAndSave(target: any) {
    this.selectedFileFromUSB = target
    if (this.selectedUSBDataSourceInstance && this.selectedFileFromUSBPath) try {
      const newInstance: DataSourceInstance = new DataSourceInstance()
      newInstance.datasourceName = this.dataSource.name
      newInstance.displayName = this.labelOfSelection(this.selectedFileFromUSBPath, this.localMountFileTree || []) || this.selectedFileFromUSBPath.split('/').slice(-1)[0]

      newInstance.authenticationDetails = {
        fsUUID: this.selectedUSBDataSourceInstance.authenticationDetails.fsUUID,
        file: this.selectedFileFromUSBPath
      }

      const id: number = await dataSourceServiceApi._createDataSourceInstance(newInstance)
      const savedInstance = dataSourceInstanceStore.state.dataSourceInstances.get(id)
      if (savedInstance) {
        this.$emit('created', savedInstance)
        this.resetIfNotVisible(false)
      }
    } catch (e) {
      this.toast.error(e.message, this.i18n.$gettext("The data from the disk could not be read."))
      this.selectedUSBFileSystem = null
      this.selectedUSBDataSourceInstance = null
      this.selectedFileFromUSB = null
    }
  }

  get selectedFileFromUSBPath(): string | undefined {
    if (this.selectedFileFromUSB) {
      for (const [key, value] of Object.entries(this.selectedFileFromUSB)) {
        if (value === true) {
          return key
        }
      }
    }
  }

  labelOfSelection(key: string | undefined, tree: any[]): string | undefined {
    for (const leaf of tree) {
      if (leaf.key == key) {
        return leaf.label || leaf.key
      }
      const childLabel = this.labelOfSelection(key, leaf.children || [])
      if (childLabel) {
        return childLabel
      }
    }
  }

  async createAndVerifyDirectAPI() {
    try {
      const newInstance: DataSourceInstance = new DataSourceInstance()
      newInstance.datasourceName = this.dataSource.name || ''
      if (this.host && !this.url) {
        this.url = this.dataSource.name + '://' + this.host
        if ([ 'ftp', 'sftp', 'imap' ].includes(newInstance.datasourceName)) {
          this.url += ':' + this.port
        }
      }
      const host = this.url.includes('://') ? this.url.split('://')[1] : this.url
      newInstance.displayName = this.displayName || (this.dataSource.displayName + ' | ' + host + ' | ' + this.userName)
      newInstance.authenticationDetails = {
        url: this.url,
        username: this.userName,
        password: this.password,
        domain: this.domain,
        encryption: this.encryption
      }

      const id: number = await dataSourceServiceApi._createDataSourceInstance(newInstance)
      const savedInstance = dataSourceInstanceStore.state.dataSourceInstances.get(id)
      if (savedInstance) {
        this.$emit('created', savedInstance)
        this.resetIfNotVisible(false)
      }
    } catch (e) {
      this.toast.error(e.message, this.i18n.$gettext("There was an error while creating the datasource. Please check the entered details."))
    }
  }

  async createEmptyOAuthSourceAndVerify() {
    try {
      const newInstance: DataSourceInstance = new DataSourceInstance()
      newInstance.datasourceName = this.dataSource.name
      newInstance.displayName = this.displayName || (this.dataSource.displayName + ' | ' + this.userName)

      const id: number = await dataSourceServiceApi._createDataSourceInstance(newInstance)
      let savedInstance: DataSourceInstance | null = dataSourceInstanceStore.state.dataSourceInstances.get(id) || null

      if (!savedInstance || !savedInstance.providerUrl || !savedInstance.id) {
        return
      }
      //Open new Window centered:
      const width = 400
      const height = 600
      const systemZoom = screen.width / window.screen.availWidth
      const left = (screen.width - width) / 2 / systemZoom + window.screenLeft
      const top = (screen.height - height) / 2 / systemZoom + window.screenTop
      const newWindow = window.open(savedInstance.providerUrl, this.i18n.$gettext("Authentication Required"), `width=${width},height=${height},left=${left},top=${top},location=no,menubar=no,status=no,toolbar=no`)
      if (newWindow) newWindow.focus()
      this.waitingForOauthConfirmation = true

      const timeoutCallback = () => {
        savedInstance = dataSourceServiceApi.getDataSourceInstance(id).data
        if (savedInstance?.confirmed) {
          this.waitingForOauthConfirmation = false
          try {
            if (newWindow && !newWindow.closed) {
              newWindow.close()
            }
          } catch (e) {
            //Ignoring this..
          }

          this.toast.success(this.i18n.$gettext("Added data source"))
          this.$emit('created', savedInstance)
          this.resetIfNotVisible(false)
        } else {
          window.setTimeout(timeoutCallback, 500)
        }
      }
      window.setTimeout( timeoutCallback, 500)
    } catch (e) {
      this.toast.error(this.i18n.$gettext("Could not createe datasource instance, please try again"))
    }
  }

  mounted() {
    this.reloadDisks()
    if (this.dataSource.name === 'imap') {
      this.port = 993
      this.encryption = 'SSL'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
