<template>
  <div class="row">
    <div v-if="!readOnly" class="col-12 col-md-auto mb-4">
      <span class="p-float-label d-inline-block">
        <Dropdown
          v-model="value"
          :disabled="readOnly"
          :options="typeSelectOptions"
          option-label="label"
          option-value="value"
          style="min-width: 10rem"
        />
        <label><translate>Type</translate></label>
      </span>
      <Button
        icon="cil-trash-alt"
        class="p-button-danger ml-2 after-inline-label"
        @click="emitDelete"
      />
    </div>
    <div v-else class="col-12 col-md-auto mb-4">
      <i class="cil-location-pin" />&nbsp;&nbsp;<strong>{{ typeString }}:</strong>
    </div>
    <div v-if="!readOnly" class="col-12 col-md mb-4">
      <div class="row mb-2">
        <div class="col">
          <span class="p-float-label">
            <InputText
              v-model="address.streetAddress"
              class="w-100"
              :disabled="readOnly"
            />
            <label><translate>Street</translate></label>
          </span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-4">
          <span class="p-float-label">
            <InputText
              v-model="address.postalCode"
              class="w-100"
              :disabled="readOnly"
            />
            <label><translate>Code</translate></label>
          </span>
        </div>
        <div class="col-8">
          <span class="p-float-label">
            <InputText
              v-model="address.locality"
              class="w-100"
              :disabled="readOnly"
            />
            <label><translate>City</translate></label>
          </span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <span class="p-float-label">
            <InputText
              v-model="address.region"
              class="w-100"
              :disabled="readOnly"
            />
            <label><translate>Region</translate></label>
          </span>
        </div>
        <div class="col">
          <span class="p-float-label">
            <InputText
              v-model="address.country"
              class="w-100"
              :disabled="readOnly"
            />
            <label><translate>Country</translate></label>
          </span>
        </div>
      </div>
    </div>
    <div v-else class="col-12 col-md mb-4 ">
      <address>
        {{ address.streetAddress }}<br>
        {{ address.postalCode }}<span v-if="address.postalCode">&emsp;</span>{{ address.locality }}<br>
        {{ address.region }}<span v-if="address.region">&emsp;</span>{{ address.country }}
      </address>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {useGettext} from "@jshmrtn/vue3-gettext"
import InputText from "primevue/inputtext"
import MultiSelect from "primevue/multiselect"
import Address from "@/model/common/carddav/Address"
import Button from "primevue/button"
import Dropdown from "primevue/dropdown"

@Options({
  components: {MultiSelect, InputText, Button, Dropdown},
  //@ts-ignore
  props: {
    address: [Address, Object],
    readOnly: {
      type: Boolean,
      default: false
    },
    isv4: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'delete' ]
})
export default class AddressField extends Vue {
  i18n = useGettext();
  index = 0;
  isv4 = false
  address!: Address

  typeMapv3: { [index:string] : string }  = {
    dom: this.i18n.$gettext("Domestic"),
    intl: this.i18n.$gettext("International"),
    postal: this.i18n.$gettext("Postal"),
    parcel: this.i18n.$gettext("Parcel"),
    work: this.i18n.$gettext("Business"),
    pref: this.i18n.$gettext("Main"),
    home: this.i18n.$gettext("Private"),
  }

  typeMapv4: { [index:string] : string }  = {
    work: this.i18n.$gettext("Business"),
    home: this.i18n.$gettext("Private"),
    individual: this.i18n.$gettext("Both")
  }


  get typeString(): string {
    const result: string = this.i18n.$gettext("Address")
    const type: string = this.value
    if(this.typeMapv3.hasOwnProperty(type)){

      return this.typeMapv3[type]
    }

    if(this.typeMapv4.hasOwnProperty(type)) {
      return this.typeMapv4[type]
    }

    return result
  }

  get typeSelectOptions() {
    let result = []
    let typeMap = this.isv4 ? this.typeMapv4 : this.typeMapv3
    for (const [key, value] of Object.entries(typeMap)) {
      result.push({label: value, value: key})
    }
    return result
  }

  emitDelete(): void {
    this.$emit('delete')
  }

  get value(): string {
    if(!this.address) return ""

    if(!this.address.types){
      this.address.types = []
    }

    return this.address?.types[0] || ""
  }

  set value(val: string) {
    if(!this.address) return

    if(!this.address.types){
      this.address.types = []
    }

    this.address.types[0] = val
  }
}
</script>

<style scoped lang="scss">

</style>
