<template>
  <div v-if="!readOnly" class="p-inputgroup mb-4">
    <span class="p-float-label">
      <Dropdown
        v-model="value"
        :disabled="readOnly"
        :options="typeSelectOptions"
        option-label="label"
        option-value="value"
      />
      <label><translate>Type</translate></label>
    </span>
    <span class="p-float-label">
      <InputText v-model="email.address" :disabled="readOnly" />
      <label><translate>Email</translate></label>
    </span>
    <Button
      v-if="!readOnly"
      icon="cil-trash-alt"
      class="p-button-danger after-inline-label"
      @click="emitDelete"
    />
  </div>
  <div v-else class="d-flex align-items-center">
    <i class="cil-envelope-closed" />&nbsp;&nbsp;<strong>{{ typeStringforType(email.types) }}:</strong>&emsp; <a :href="'mailto:' + email.address">{{ email.address }}</a>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import EmailAddress from "@/model/common/EmailAddress"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import InputText from "primevue/inputtext"
import MultiSelect from "primevue/multiselect"
import Button from "primevue/button"
import Dropdown from "primevue/dropdown"

@Options({
  components: {InputText, MultiSelect, Button, Dropdown},
  //@ts-ignore
  props: {
    email: [Object, EmailAddress],
    readOnly: {
      type: Boolean,
      default: false
    },
    isv4: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'delete' ]
})
export default class EmailField extends Vue {
  i18n: Language = useGettext()
  isv4 = false
  index = 0
  email!: EmailAddress

  typeMapv3: { [index:string] : string } = {
    internet: this.i18n.$gettext("Email"),
    other: this.i18n.$gettext("Other"),
    home: this.i18n.$gettext("Private"),
    pref: this.i18n.$gettext("Main"),
    work: this.i18n.$gettext("Business")
  }

  typeMapv4: { [index:string] : string } = {
    work: this.i18n.$gettext("Business"),
    home: this.i18n.$gettext("Private"),
    individual: this.i18n.$gettext("Both")
  }


  typeStringforType(types: string[]): string {
    const result: string = this.i18n.$gettext("Email")
    if(types.length === 0){
      return result
    }
    const type: string = types[0]
    if(this.typeMapv3.hasOwnProperty(type)){

      return this.typeMapv3[type]
    }

    if(this.typeMapv4.hasOwnProperty(type)) {
      return this.typeMapv4[type]
    }

    return result
  }

  get typeSelectOptions() {
    let result = []
    const typeMap = this.isv4 ? this.typeMapv4 :  this.typeMapv3
    for (const [key, value] of Object.entries(typeMap)) {
      result.push({label: value, value: key},)
    }
    return result
  }

  emitDelete(): void {
    this.$emit('delete')
  }

  get value(): string {
    if(!this.email) return ""

    if(!this.email.types){
      this.email.types = []
    }

    return this.email?.types[0] || ""
  }

  set value(val: string) {
    if(!this.email) return

    if(!this.email.types){
      this.email.types = []
    }

    this.email.types[0] = val
  }

}
</script>

<style scoped lang="scss">

</style>
